import { CharacterTextureMessage } from "@workadventure/messages";
import type { GameScene } from "../Game/GameScene";
import { TexturesHelper } from "../Helpers/TexturesHelper";
import { CharacterTextureError } from "../../Exception/CharacterTextureError";
import { gameManager } from "../Game/GameManager";
import { lazyLoadPlayerCharacterTextures } from "./PlayerTexturesLoadingManager";

/**
 * Class that let you generate a base64 image from a CharacterLayer[]
 */
export class CharacterLayerManager {
    static wokaBase64(characterTextures: CharacterTextureMessage[]): Promise<string> {
        const scene = gameManager.getCurrentGameScene();
        return lazyLoadPlayerCharacterTextures(
            scene.superLoad,
            characterTextures.map((texture) => ({ id: texture.id, url: texture.url }))
        )
            .then((textures) => {
                return this.getSnapshot(scene, this.getSprites(scene, textures)).then((htmlImageElementSrc) => {
                    return htmlImageElementSrc;
                });
            })
            .catch(() => {
                return lazyLoadPlayerCharacterTextures(scene.superLoad, [
                    {
                        id: "color_22",
                        url: "resources/customisation/character_color/character_color21.png",
                    },
                    {
                        id: "eyes_23",
                        url: "resources/customisation/character_eyes/character_eyes23.png",
                    },
                ])
                    .then((textures) => {
                        return this.getSnapshot(scene, this.getSprites(scene, textures)).then((htmlImageElementSrc) => {
                            return htmlImageElementSrc;
                        });
                    })
                    .catch((e) => {
                        throw e;
                    });
            });
    }

    private static async getSnapshot(
        scene: GameScene,
        sprites: Map<string, Phaser.GameObjects.Sprite>
    ): Promise<string> {
        // Créer une texture temporaire pour le visage
        const rt = scene.make.renderTexture({
            width: 64,
            height: 64
        }, false);

        try {
            for (const sprite of sprites.values()) {
                if (sprite.texture.key.includes("color") || sprite.texture.key.includes("male")) {
                    // Configurer le sprite pour montrer le visage (frame de face)
                    sprite.setFrame(1);  // La frame de face
                    
                    // Dessiner uniquement la partie du visage
                    rt.draw(sprite.texture, 0, 0, 64, 64, 0, 0);
                    
                    // Convertir en base64
                    return new Promise((resolve) => {
                        rt.snapshot((image) => {
                            resolve(image instanceof HTMLImageElement ? image.src : image);
                            rt.destroy();
                        });
                    });
                }
            }
            return "male1";
        } catch (error) {
            rt.destroy();
            console.warn("Error generating woka face:", error);
            return "male1";
        }
    }

    private static getSprites(
        scene: GameScene,
        textures: string[],
        frame?: string | number
    ): Map<string, Phaser.GameObjects.Sprite> {
        const sprites = new Map<string, Phaser.GameObjects.Sprite>();
        if (textures.length < 1) {
            throw new CharacterTextureError("no texture given");
        }

        for (const texture of textures) {
            if (scene && !scene.textures.exists(texture)) {
                throw new CharacterTextureError("texture not found");
            }
            const sprite = new Phaser.GameObjects.Sprite(scene, 0, 0, texture, frame);

            sprites.set(texture, sprite);
        }
        return sprites;
    }
}
